.forecastKpiCard{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120px;
    gap: 64px;
}

.forecast-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.forecast-container.border{
    border-left: 1px solid #D4D4D4;
    padding-left: 16px;
}

.forecast-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 83px;
  height: 83px;
  border-radius: 50%;
}

.forecast-label {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.forecast-values {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.forecast-main-values {
  color: #394960;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.forecast-profit-values.profit {
  color: #24a148;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.forecast-profit-values.loss {
    color: #DA1E28;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
