.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-align-end {
  display: flex;
  align-items: flex-end;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-justify-space-between {
  display: flex;
  justify-content: space-between;
}

.mr-20 {
  margin-right: 20px;
}

.container {
  padding-bottom: 100px;
}

.kpi-container {
  width: 620px;
  margin-left: 40px;
  padding: 16px 30px 20px 16px;
  border: 0.0625rem solid #c8ced0;
  border-radius: 3px;
}

.kpi-icon-container {
  padding: 6px 8px;
  background-color: #e6f2fe;
  border-radius: 3px;
}

.kpi-title {
  margin-left: 10px;
  color: #2d3b49;
  font-size: 16px;
  font-weight: bold;
}

.kpi-details-container {
  margin-top: 16px;
}

.unit-wrapper {
  //   padding-left: 20px;
}

.unit-container {
  padding: 0px 30px;
  border-right: 0.0625rem solid #c8ced0;

  &:last-child {
    border: none;
  }
}

.unit-label {
  margin-bottom: 4px;
  color: #758398;
}

.unit-value {
  font-weight: bold;
}

.total-container {
  //   margin-left: 60px;
}

.total-label {
  margin-bottom: 4px;
  color: #758398;
}

.total-value {
  color: #0054af;
  font-size: 20px;
  font-weight: bold;
}
