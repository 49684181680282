.archer-node{
    padding: 10px;
    border:1px solid #ccc;
    border-radius: 4px;
    font-size: 13px;
    max-width: fit-content;
    min-width: 120px;
}

.archer-field{
    width: 100%;
    height: 100%;
}
.archer-scrollbox{
    width: 250px;
    max-height: 180px;
    overflow-y: scroll;
    scrollbar-width: thin;
    border-radius: 3px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 12px rgb(128, 128, 128,0.15);
    
}
.archer-scrollbox ul{
    list-style-type: none;
}
.archer-scrollbox li{
    margin-bottom: 0.75rem;
}
.archer-main-container{
    border-radius: 3px;
    border: 1px solid #ddd;
    /* box-shadow: 0px 5px 12px rgb(128, 128, 128,0.15); */
    margin:10px;
}

