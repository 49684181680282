.chatWindow p {
  margin: 0;
  padding: 0;
}
/* CHAT ICON STYLING */
.chatBubble {
  border-radius: 41px;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(137.01deg, #5152c2 27.69%, #ce30dc 88.31%);
  box-shadow: 0px 4px 4px 0px #00000040;
  box-shadow: 2px 1px 3px 0px #ffffff73 inset;
  box-shadow: -2px -2px 4px 0px #00000059 inset;
  position: fixed;
  right: 64px;
  bottom: 40px;
  z-index: 1;
  padding: 0 16px;
  color: white;
  cursor: pointer;
  gap: 16px;
}

.chatIcon {
  color: white;
  width: 24px;
}
.chatBubble p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
}

/* CHAT POPUP STYLING  */
.chatWindow {
  box-shadow: 0px 0px 8px 0px #00000029;
  background: #ffffff;
  height: 99vh;
  width: 760px;
  position: fixed;
  z-index: 2;
  top: 0.5vh;
  right: 0;
  border-radius: 4px;
}

/* CHAT POPUP HEADER STYLING  */
.chatbotHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  padding: 8px 16px;
  border-bottom: 1px solid #efefef;
}
.chatbotHeader div {
  display: flex;
  gap: 8px;
  align-items: center;
}
.chatbotHeader p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;
}


.auth-status{
  font-size: 16px;
}
.close-icon {
  /* padding: 4.17px 4.12px 4.17px 4.12px;
  border-radius: 4px; */
  font-size: 18px;
}
.date {
  padding: 4px 15px 4px 15px;
  border-radius: 4px;
  background: #f9f9f9;
  box-shadow: 0px 2px 4px 0px #00000012;
  width: max-content;
  margin: 8px auto;
  color: #78838f;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}
.bottom-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 8px;
}
/* BOTTOM ACTION BUTTON STYLING  */
.bottom-button-wrapper {
  width: 100%;
  height: 50px;
  padding: 12px 20px 12px 20px;
  border-radius: 0px 0px 4px 4px;
  opacity: 0px;
  display: flex;
  justify-content: end;
  align-items: center;
  background: #eef0f7;
}
/* 
.parent-card-container {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.parentCardBody {
  width: 348px;
  height: 105px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px #0000001f;
  border-radius: 10px;
  padding: 10px;
}
.parent-card-header-container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  height: 35px;
}

.parent-card-header-container p {
  margin-top: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1d1d1d;
}
.parent-card-header-container img {
  border: 1px solid black;
  width: 13.42px;
  height: 23px;
}

.parent-card-desciption {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  margin-top: 8px;
  text-align: left;
  color: #626262;
  width: 90%;
} */

/* CHAT MESSAGE CONTAINER STYLING  */
.message-container {
  padding: 8px 16px;
  max-height: calc(99vh - 95px);
  overflow: auto;
}

.msg-bubble {
  width: 640px;
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 8px;
  cursor: pointer;
}
.ChatGPT {
  background: #f8f9ff;
}
.user {
  background: #f9f9f9;
  margin-left: auto;
}
.msg-bubble-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.msg-bubble-header .sender-name {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #78838f;
}

.timestamp {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: right;
  color: #78838f;
}

.msg-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
.mg-t {
  margin-top: 8px !important;
}
.card-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
  flex-wrap: wrap;
}
.card-item {
  padding: 4px 15px 4px 15px;
  border-radius: 16px;
  border: 1px;
  background: #f0f1ff;
  /* border: 1px solid #646ce7; */
  color: #646ce7;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  white-space: nowrap;
}

/* MESSAGE INPUT CONTAINER  */
.msg-input-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 0 16px;
  height: 37px;
}
.msg-inputfield {
  flex-grow: 1;
  border-radius: 3px;
  border: 1px solid #acacac;
  background: #ffffff;
  padding: 4px 8px;
}
.msg-inputfield::placeholder {
  color: #afafaf;
}

.send-btn {
  width: 41px !important;
  max-width: 41px !important;
  min-width: 41px !important;
  padding: 8px 8px 9px 18px !important;
  border-radius: 4px !important;
  background: #646ce7 !important;
}

.ques-item {
  display: flex;
  align-items: start;
  gap: 8px;
}
.ques-label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #141414;
  white-space: nowrap;
  width: 50px;
}
.ques-value {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #646ce7;
}

#chat-action-wrapper {
  display: flex;
  align-items: start;
  gap: 16px;
}

#chat-action-wrapper p {
  color: #0055af;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  cursor: pointer;
}

.std-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

/* LOADER STYLE  */
/* div#wave {
  position: relative;
  border: 1px solid red;
} */
div#wave .dot {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 3px;
  background: #5e5e5e;
  animation: wave 1.3s linear infinite;
}
div#wave .dot:nth-child(2) {
  animation-delay: -1.1s;
}
div#wave .dot:nth-child(3) {
  animation-delay: -0.9s;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-15px);
  }
}

/* AUTO COMPLETE STYLES  */

.suggestion-container {
  position: absolute;
  bottom: 64px;
  width: 85%;
  margin-left: 16px;
  z-index: "999";
  border-radius: "5px";
  box-shadow: 0px 0px 6px #b0b0b0;
  background: #ffffff;
  padding: 0 8px;
}

.suggestion-item {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  border-top: 1px solid #efefef;
  padding: 8px 0;
}

.list-wrapper {
  list-style-type: circle;
  padding: 0 !important;
}
.list-item {
  display: flex;
  align-items: start;
  gap: 8px;
}
.list-item .bullet{
  font-size: 10px;
  margin-top: 5px;
}
.list-label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #141414;
  white-space: nowrap;
}
.list-value {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #646ce7;
}

/* Steps */

.msg-action-button{
  background: #0055af;
  border: none;
  color: white;
  border-radius: 3px;
}
.msg-action-button-marked{
  background: none;
  border: 1px solid #0055af ;
  color: #0055af;
  border-radius: 3px;
}
.step-text{
  white-space: pre-wrap;
  font-size: small;
}
.step-text ul{
  list-style-type: disc;
}
.step-text ul ul{
  list-style-type: circle;
}
.step-container{
  display: flex;
  flex-direction: column;
}