body {
  margin: 0;
  font-family: Verdana;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Verdana
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid gray;
}

.filter-container {
  padding: 15px;
  border: 1px solid #c8ced7;
  border-radius: 3px;
  position: relative;
  padding-left: 30px;
}

.filter-container h2 {
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #1d1d1d;
}

.filter-container::after {
  content: " ";
  position: absolute;
  width: 2px;
  background: #0055af;
  top: 10px;
  bottom: 10px;
  left: 15px;
}

#rdts2_trigger {
  width: 100%;
  height: 35px;
  max-height: 35px;
  border-radius: 3px;
  border: 0.5px solid #96a8b9;
}

#rdts2 .dropdown {
  width: 307px;
}

.react-daterange-picker__wrapper {
  height: 35px !important;
}

.filterGroup label {
  font-size: 12px !important;
  color: #758490 !important;
}

hr {
  margin: 1% !important;
  border-top: 1px solid #d4d4d4 !important;
}

.cluster-container {
  padding: 15px;
  border: 1px solid #c8ced7;
  border-radius: 3px;
  position: relative;
  padding-left: 30px;
}

.cluster-container::after {
  content: " ";
  position: absolute;
  width: 2px;
  background: #0055af;
  top: 10px;
  bottom: 10px;
  left: 15px;
}

.card-checkbox-container {
  border: 1px solid #d4d4d4;
  padding: 10px;
  margin-right: 10px;
  min-height: 300px;
  border-radius: 3px;
  background: rgba(239, 239, 239, 0.4);
}

.form-main-container {
  padding: 15px;
  border: 1px solid #c8ced7;
  border-radius: 3px;
  position: relative;
  padding-left: 30px;
}

.form-main-container::after {
  content: " ";
  position: absolute;
  width: 2px;
  background: #0055af;
  top: 10px;
  bottom: 10px;
  left: 15px;
}

.ag-header-cell-label {
  color: #1d1d1d;
}

.card-parent-container {
  padding: 15px;
  border: 1px solid #c8ced7;
  border-radius: 3px;
  position: relative;
  padding-left: 30px;
  margin: 15px;
}

.card-parent-container::after {
  content: " ";
  position: absolute;
  width: 2px;
  background: #0055af;
  top: 10px;
  bottom: 10px;
  left: 15px;
}
