     
.node_button:focus{
    background-color: red !important;
    color: white;
    }
.node_button:hover{
        background-color: red !important;
        color: white;
        }
   
        
.cont{
            width: 100%;
            overflow-y: scroll;
            
        }

.node_button::-webkit-scrollbar{
width:1px;

background-color: rgb(234, 234, 234);


}

         