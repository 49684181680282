.calendar-wrapper {
	clip-path: inset(-10px -10px -10px 0px);
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 6px #00000029;
	border-radius: 5px;
	width: 100%;
}

.no-content {
	display: flex;
	height: 100px;
	width: 100%;
}

.calendar-scroll-container {
	height: 100%;
	border-bottom: 2px solid #acacac61;
}

.header-row {
	height: 40px;
	display: flex;
	background-color: #f7f7f7;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #758498;
	overflow-y: hidden;
}

.header-row.scroll {
	overflow-y: scroll;
}

.header-row .header-cell {
	text-align: center;
	letter-spacing: 0px;
	color: #1d1d1d;
	font-family: Poppins;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
}

.navigation-arrow-left,
.navigation-arrow-right {
	display: inline-flex;
	border: 1px solid #e2e2e2;
	border-radius: 4px;
	cursor: pointer;
}

.navigation-arrow-left {
	margin-left: 10px;
}

.navigation-arrow-right {
	margin-right: 10px;
}

.header-title-container {
	flex: 1;
}

.header-title-container .header-title {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
}

.header-title-container .header-title svg {
	height: 16px;
	width: 16px;
	fill: #0055af;
	cursor: pointer;
	position: relative;
	top: 2px;
}

.header-row.month {
	background-color: #ffffff;
}

.header-row.month .header-cell {
	font-weight: 600;
	font-size: 14px;
	line-height: 40px;
	border-left: 2px solid #7584987a;
}

.header-row.week .header-cell {
	font-size: 12px;
	line-height: 40px;
}

.header-row.week .header-cell.day {
	border-left: 1px solid #c8ced7;
}

.header-row.week .header-cell.month {
	border-left: 2px solid #7584987a;
}

.header-row.day {
	height: 16px;
}

.header-row.day .header-cell {
	font-size: 10px;
	line-height: 16px;
}

.header-row.day .header-cell.day {
	border-left: 1px solid #c8ced7;
}

.header-row.day .header-cell.week {
	border-left: 1px solid #c8ced7;
}

.header-row.day .header-cell.month {
	border-left: 2px solid #7584987a;
}

.header-row.month .header-cell:nth-of-type(1),
.header-row.week .header-cell:nth-of-type(1),
.header-row.day .header-cell:nth-of-type(1) {
	border-left: transparent;
}

.flex-c-a {
	justify-content: center;
	align-items: center;
}

.event-list-container {
	min-height: 240px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.event-list-container.max-height {
	max-height: 320px;
}

.event-list-container.no-max-height {
	max-height: 100%;
}

.event-list-container-wrapper {
	position: relative;
}

.no-scroll {
	overflow: hidden;
}

.cal-row {
	display: flex;
	position: relative;
	min-height: 40px;
	border-bottom: 1px solid #e2e2e2;
}

.cal-cell {
	height: 40px;
	box-sizing: border-box;
}

.cal-cell.day {
	border-left: 1px dashed #eaebedb8;
}

.cal-cell.week {
	border-left: 1px solid #c8ced7;
}

.cal-cell.month {
	border-left: 2px solid #7584987a;
}

.cal-cell:nth-of-type(1) {
	border-left: transparent;
}

.event-container {
	border: 1px solid #24a148;
	border-radius: 4px;
	display: flex;
	position: absolute;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	height: 30px;
	z-index: 99;
	align-items: center;
	padding: 0px 5px;
	box-sizing: border-box;
	margin-top: 5px;
}

.event-container:hover + .event-detail-container {
	display: block;
	width: 240px;
}

.event-detail-container:hover {
	display: block;
	width: 240px;
}

.event-container > p.event-title {
	font-size: 12px;
	display: flex;
	gap: 3px;
	align-items: center;
}

.event-container > p.event-title .event-title-text {
	text-overflow: ellipsis;
	flex: 1;
	white-space: nowrap;
	overflow: hidden;
}

.event-container > p.event-title svg {
	width: 16px;
	height: 16px;
	fill: #ff832b;
	background: #fff;
	padding: 1px;
	border-radius: 50%;
}

.event-container.good,
.event-container.completed,
.event-container.ongoing {
	background: #24a148;
	border: 1px solid #24a148;
	color: #fff;
	font-weight: 500;
}

.event-container.average,
.event-container.past {
	background: #ff832b;
	border: 1px solid #ff832b;
	color: #fff;
	font-weight: 500;
}

.event-container.toxic {
	background: #da1e28;
	border: 1px solid #da1e28;
	color: #fff;
	font-weight: 500;
}

.event-container.upcoming{
	background: #0055af;
	border: 1px solid #0055af;
	color: #fff;
	font-weight: 500;
}

.event-container.interrupter{
	background: #EFD300;
	border: 1px solid #EFD300;
	color: #fff;
	font-weight: 500;
}

.event-container.firstClr{
	background: #EAF2FC;
	border: 1px solid #66A0EF;
	color: #66A0EF;
	font-weight: 500;
}

.event-container.furtherClr{
	background: #F5F4FF;
	border: 1px solid #8373FF;
	color: #8373FF;
	font-weight: 500;
}

.event-container.epoc{
	background: #F0FFFC;
	border: 1px solid #2CB196;
	color: #2CB196;
	font-weight: 500;
}

.event-detail-container {
	display: none;
	position: absolute;
	z-index: 999;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 6px #00000029;
	border: 1px solid #eaebed;
	border-radius: 4px;
	padding: 15px;
	width: 250px;
}

.event-container.selected {
	background: #00accb;
	border: 1px solid #00accb;
	color: #ffffff;
}

.event-container .offer-value {
	max-width: 100px;
	padding: 0 20px;
	box-sizing: border-box;
	height: 35px;
	border-radius: 17px;
	align-items: center;
	display: flex;
	justify-content: center;
	text-align: left;
	font-size: 14px;
	line-height: 21px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #ffffff;
}

.event-container.good .offer-value,
.event-container.completed .offer-value {
	background: #24a148 0% 0% no-repeat padding-box;
}

.event-container.average .offer-value {
	background: #ff832b 0% 0% no-repeat padding-box;
}

.event-container.toxic .offer-value {
	background: #da1e28 0% 0% no-repeat padding-box;
}

.event-container.upcoming .offer-value,
.event-container.ongoing .offer-value {
	background: #0055af 0% 0% no-repeat padding-box;
}

.event-container.selected .offer-value {
	background: #0055af 0% 0% no-repeat padding-box;
}

.event-container.events .offer-value {
	background: #0055af 0% 0% no-repeat padding-box;
}

.event-container p {
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

.event-content .event-title {
	text-align: left;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.07px;
	display: flex;
	align-items: center;
	gap: 10px;
}

.event-list-container-wrapper .loading {
	position: absolute;
	z-index: 1000;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	background: rgba(255, 255, 255, 0.701) 50% 50% no-repeat;
}

.loading-text {
	position: absolute;
	z-index: 1001;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	color: #1976d2;
}

.calendar-events-loading-wrapper {
	position: relative;
}

.event-legend {
	display: flex;
	gap: 20px;
	padding: 20px 0 0 0;
	align-items: center;
	justify-content: left;
}

.event-legend-content {
	display: flex;
	align-items: center;
}

.event-legend-content-circle {
	height: 16px;
	width: 16px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 5px;
}

.event-legend-content-text {
	text-align: left;
	font-size: 14px;
	line-height: 21px;
	font-weight: 600;
	letter-spacing: 0.08px;
}

.event-title-wrapper {
	display: flex;
	align-items: center;
	gap: 10px;
	text-align: left;
	font: normal normal 600 14px/21px Poppins;
	letter-spacing: 0.08px;
	color: #1d1d1d;
	text-transform: capitalize;
	margin-bottom: 15px;
}

.event-title-wrapper .event-title {
	margin: 0;
	flex: 1 1;
}

.event-title-wrapper svg {
	width: 16px;
	height: 16px;
}

.event-detail {
	display: flex;
	justify-content: left;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
}

.event-detail p {
	text-align: left;
	font-size: 12px;
	letter-spacing: 0.08px;
	color: #758490;
	margin: 0;
}

.event-detail svg {
	height: 16px;
	width: 16px;
	fill: #758490;
}

.event-detail-container .status-indicator {
	display: inline-block;
	border-radius: 50%;
	width: 14px;
	height: 14px;
}

.event-detail-container.good .status-indicator,
.event-detail-container.completed .status-indicator,
.event-detail-container.ongoing .status-indicator {
	background-color: #24a148;
}

.event-detail-container.average .status-indicator,
.event-detail-container.past .status-indicator {
	background-color: #ff832b;
}

.event-detail-container.toxic .status-indicator {
	background-color: #da1e28;
}

.event-detail-container.upcoming .status-indicator {
	background-color: #0055af;
}

.event-detail-container.interrupter .status-indicator {
	background-color: #EFD300;
}

.event-detail-container.firstClr .status-indicator {
	background-color: #EAF2FC;
	border: 1px solid #66A0EF;
}

.event-detail-container.furtherClr .status-indicator {
	background: #F5F4FF;
	border: 1px solid #8373FF;
}

.event-detail-container.epoc .status-indicator {
	background: #F0FFFC;
	border: 1px solid #2CB196;
}

.event-title-wrapper > .MuiSvgIcon-root,
.event-more-actions-container > .MuiSvgIcon-root,
.event-footer .MuiSvgIcon-root {
	cursor: pointer;
	width: 18px;
	height: 18px;
	fill: #0055af;
}

.event-footer .MuiSvgIcon-root:hover {
	fill: #033162;
}

.event-footer .MuiSvgIcon-root:disabled,
.event-footer svg[disabled] {
	fill: rgba(117, 132, 144, 0.5019607843);
	color: rgba(117, 132, 144, 0.5019607843);
}

.event-footer {
	display: flex;
	gap: 20px;
	margin-top: 20px;
	justify-content: left;
	align-items: center;
}

.disabled-lock {
	color: #75849080;
}

.event-container.selected .promo-content .event-title-wrapper .MuiSvgIcon-root {
	color: #ffffff;
}

p.new-tag {
	padding: 2px 10px;
	background: #0055af;
	border-radius: 4px;
	color: #fff;
	margin: 0;
}

// Calender Side Panel Css

.calendar-side-panel{
	border-bottom: 2px solid rgba(172, 172, 172, 0.3803921569);
	background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1607843137);
	min-width: 200px;
	border-right: 2px solid rgba(117, 132, 152, 0.4784313725);
	background-color: #f7f7f7;
}

.calendar-side-panel-header{
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 96px;
	border-bottom: 1px solid #c8ced7;
}

.events-panel {
    font-size: 14px;
    line-height: 40px;
    display:flex;
    flex-direction:row;
}

.events-panel-parent {
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom: 1px solid #c8ced7;
	border-right: 1px solid #c8ced7;
    width:50%;
}

.events-panel-child {
    width:50%;
}

.events-panel-children {
    display:flex;
    justify-content:center;
    align-items:center;
    border-bottom: 1px solid #c8ced7;
}
