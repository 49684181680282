.cell-info-tag-container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.tag-chip {
  border-width: 1px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 4px;
  min-width: 30px;
}
.tag-chip-sm {
  padding: 4px;
  font-size: 10px;
}
.tag-chip-xs {
  padding: 2px;
  font-size: 9px;
}
.tag-chip-popper {
  font-size: 10px;
  border-radius: 4px;
  background-color: #f2f2f2;
  padding: 4px;
  min-width: 50px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}
